import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取服务管理列表
export const serviceList = (size, page, data) => {
  const submitData = {
    '$paging.size': size,
    '$filter.name': 'null',
    '$paging.page': page,
    '$orderby': 'null',
    '$filter.params': data,
  };
  return fetchApi(URL.SERVICE_TYPE_LIST, submitData)
}

// 新增
export const addServiceInfo = (data) => {
  return fetchApi(URL.ADD_SERVICE_INFO, data, 'post', 'params')
}

// 查询服务类型
export const getTypeList = () => {
  return fetchApi(URL.GET_TYPE_LIST,null)
}

// 编辑
export const editServiceInfo = (data) => {
  return fetchApi(URL.EDIT_SERVICE_INFO, data, 'post', 'params')
}

// 查看详情
export const detailServiceInfo = (data) => {
  return fetchApi(URL.DETAIL_SERVICE_INFO, data)
}
// 页面入口下拉列表
export const serviceNumberList = (data) => {
  return fetchApi(URL.SERVICE_NUMBER_LIST, data)
}

// 新的服务列表
export const newServiceList = (size, page, params) => {
  const data = {
    currentPage: page,
    pageSize: size,
    serviceName: params.serviceName,
    penetrate: params.penetrate
  }
  return fetchApi(URL.NEW_SERVICE_LIST, data)
}

// 新的添加服务
export const newAddService = (data) => {
  return fetchApi(URL.NEW_ADD_SERVICE, data, 'post', 'data')
}

// 新的查看详情
export const newDetailServiceInfo = (data) => {
  return fetchApi(URL.NEW_DETAIL_SERVICE_INFO + data, data)
}

// 新的服务编辑
export const newEditServiceInfo = (data) => {
  return fetchApi(URL.NEW_EDIT_SERVICE, data, 'PUT', 'data')
}
